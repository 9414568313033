<template>
  <div class="kt-grid kt-grid--ver kt-grid--root">
    <div
      class="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v5"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <div class="kt-error_container">
        <span class="kt-error_title">
          <h1>Oops!</h1>
        </span>
        <p class="kt-error_subtitle">
          Something went wrong here.
        </p>
        <p class="kt-error_description">
          We're working on it and we'll get it fixed<br />
          as soon possible.<br />
          You can back or use our Help Center.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-5.scss";
</style>

<script>
export default {
  name: "Error-5",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/error/bg5.jpg";
    }
  }
};
</script>
